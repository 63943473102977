import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql } from "gatsby";
import GameCard from "../components/game-card";
import { Row } from 'react-bootstrap';

const IndexPage = ({ data }) => {
  const gamesData = data.allGamesJson.edges

  return (
    <Layout>
      <Seo title="Home" />
      <div style={{ textAlign: "center" }}>
        <h1>Welcome to MXT Games!</h1>
        <Row>
          {
            gamesData.map(gameNode => {
              const game = gameNode.node
              return (
                <GameCard gameInfo={game} key={game.id} />
              )
            })
          }
        </Row>
        <br />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allGamesJson {
      edges {
        node {
          name
          id
          store_links {
            link
            store_type
          }
          logo_image {
            childImageSharp {
              fluid {
                srcSet
                src
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
