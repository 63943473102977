import React, { Component } from "react"
import { Card, Col } from 'react-bootstrap';

import { openInNewTab } from "../utils/utils"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faItchIo, faSteam, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

class GameCard extends Component {

    render() {
        const gameData = this.props.gameInfo

        return (
            <Col sm={6} style={{ padding: 15 }}>
                <Card className="text-center">
                    <Card.Img variant="top" srcSet={gameData.logo_image.childImageSharp.fluid.srcSet} alt={gameData.name} />
                    <Card.Body>
                        <Card.Title>{gameData.name}</Card.Title>
                        <Card.Text>
                            {
                                gameData.store_links.map(storeLink => {
                                    return <StoreLinkButton
                                        key={gameData.name + storeLink.store_type}
                                        storeType={storeLink.store_type}
                                        storeLink={storeLink.link}
                                        gameName={gameData.name} />
                                })
                            }
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

const StoreLinkButton = (props) => {
    const storeType = props.storeType
    const storeLink = props.storeLink
    const gameName = props.gameName
    let icon = null

    switch (storeType) {
        case "steam":
            icon = faSteam
            break;
        case "itch":
            icon = faItchIo
            break;
        case "google-play":
            icon = faGooglePlay
            break;
        case "web":
            icon = faGlobe
            break;
        default:
            icon = null
            break;
    }

    if (icon != null) {
        return (
            <FontAwesomeIcon style={{ marginLeft: 8, marginRight: 8, cursor: 'pointer' }} onClick={() => {
                openInNewTab(storeLink)
                trackCustomEvent({
                    category: "Games",
                    action: storeType,
                    label: gameName
                })
            }
            } icon={icon} size="2x" />
        );
    } else {
        return (<></>)
    }
}
export default GameCard